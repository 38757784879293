import React from "react";

export const Roadmap = (props) => {
  return (
    <div id="roadmap">
      <div className="container">
        <div className="section-title text-center">
          <h2>Roadmap</h2>
        </div>
        <div className="row">
          <img src="img/roadmap.svg" className="img-responsive" alt="" />
        </div>
      </div>
    </div>
  );
};
