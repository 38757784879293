import React from "react";

export const Mission = (props) => {
  return (
    <div id="mission" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="col-md-12 section-title">
              <h2>Missão</h2>
            </div>
            <p style={{ textAlign: "center" }}>
              Promover uma economia rural sustentável através de uma plataforma
              digital que elimina intermediários, reduz a pegada ecológica, e
              assegura transações comerciais justas e transparentes, valorizando
              a biodiversidade e os ecossistemas locais.
            </p>
          </div>
          <div className="col-lg-6">
            <div className="col-md-12 section-title">
              <h2>Visão</h2>
            </div>
            <p style={{ textAlign: "center" }}>
              Ser um líder no mercado brasileiro de plataformas digitais
              agrícolas, defendendo uma gestão ambiental responsável e usando
              inovação tecnológica para minimizar impactos ambientais e
              impulsionar o desenvolvimento sustentável.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
